import React from "react";
import {Link} from "gatsby";
import Img from "gatsby-image"

const ContentRow = ({className=[],level1=[], level2=[], level3=[], btnData, imgData, imgAlt="ALMobile Img", videoData={videoSrc: null, videoPoster: null}}) => {
    return (
        <div className={`contentRow ${className.map(elem => elem).join(" ")}`}>
            <div className={"subContentRow"}>
                <div className={"textContainer"}>
                    {
                        level1.map((elem, index) => {
                            return (
                                <div
                                    key={index}
                                    className={"level1 text"}
                                >
                                    {elem}
                                </div>
                            )
                        })
                    }
                    {
                        level2.map((elem, index) => {
                            return (
                                <div
                                    key={index}
                                    className={"level2 text"}
                                >
                                    {elem}
                                </div>
                            )
                        })
                    }
                    {
                        level3.map((elem, index) => {
                            return (
                                <div
                                    key={index}
                                    className={"level3 text"}
                                >
                                    {elem}
                                </div>
                            )
                        })
                    }
                    {
                        (btnData) &&
                            <Link to={`${btnData.to}`}>btnData.label</Link>
                    }
                </div>
                { (imgData) ?
                    <Img
                        className={"imgContainer"}
                        style={{ display: "block", margin: 0 }}
                        alt={imgAlt}
                        fluid={imgData}
                    />
                    : (videoData.videoSrc) ?
                        <video
                            poster={videoData.videoPoster}
                        >
                            <source src={videoData.videoSrc}/>
                        </video>
                    : <></>
                }
            </div>
        </div>
    );
};

export default ContentRow;