import React from "react";
import {Link, useStaticQuery, graphql} from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import loopVideo from "../images/ALMobileLoopClip.mp4";
import ContentRow from "../components/contentRow";
import NestedContentRow from "../components/nestedContentRow";
import ALTile from "../components/ALTile";

const IndexPage = () => {
    const imgData = useStaticQuery(graphql`
        query {
            fullClientDesktop: file(relativePath: { eq: "AllDevices.png" }) {
                childImageSharp {
                    fluid(maxHeight: 350) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            deviceIcon: file(relativePath: { eq: "DeviceIcon.png" }) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cogIcon: file(relativePath: { eq: "CogIcon.png" }) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            kioskIcon: file(relativePath: { eq: "KioskIcon.png" }) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            fieldWorkerIcon: file(relativePath: { eq: "FieldWorkerIconBlue.png" }) {
                childImageSharp {
                    fixed(width: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            clipboardIcon: file(relativePath: { eq: "ClipboardIconBlue.png" }) {
                childImageSharp {
                    fixed(width: 40) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            connectedIcon: file(relativePath: { eq: "CloudIcon.png" }) {
                childImageSharp {
                    fixed(width: 55, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            railworksLogo: file(relativePath: { eq: "RailWorksLogo.png" }) {
                childImageSharp {
                    fixed(width: 150) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            tdiLogo: file(relativePath: { eq: "TDILogo.png" }) {
                childImageSharp {
                    fixed(width: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            McCarthyLogo: file(relativePath: { eq: "McCarthyLogo.png" }) {
                childImageSharp {
                    fixed(width: 160) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            level10Logo: file(relativePath: { eq: "Level10.png" }) {
                childImageSharp {
                    fixed(width: 160) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cecmLogo: file(relativePath: { eq: "CECMLogo.png" }) {
                childImageSharp {
                    fixed(width: 160) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            sageLogo: file(relativePath: { eq: "SageLogo.png" }) {
                childImageSharp {
                    fixed(width: 130) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            viewpointLogo: file(relativePath: { eq: "ViewpointOneLogo.png" }) {
                childImageSharp {
                    fixed(width: 160) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            oracleJDELogo: file(relativePath: { eq: "OracleJDELogo.png" }) {
                childImageSharp {
                    fixed(width: 160) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cmicLogo: file(relativePath: { eq: "CMICLogo.jpg" }) {
                childImageSharp {
                    fixed(width: 160) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            pentaTechLogo: file(relativePath: { eq: "PentaTechLogo.jpg" }) {
                childImageSharp {
                    fixed(width: 160) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            computerGuidanceLogo: file(relativePath: { eq: "ComputerGuidanceLogo.png" }) {
                childImageSharp {
                    fixed(width: 160) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            constructionIcon: file(relativePath: { eq: "constructionIcon.png" }) {
                childImageSharp {
                    fixed(width: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            electricalIcon: file(relativePath: { eq: "electricalIcon.png" }) {
                childImageSharp {
                    fixed(width: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            environmentalIcon: file(relativePath: { eq: "environmentalIcon.png" }) {
                childImageSharp {
                    fixed(width: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            heavyHighwayIcon: file(relativePath: { eq: "HeavyHighwayIcon.png" }) {
                childImageSharp {
                    fixed(width: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            industrialIcon: file(relativePath: { eq: "IndustrialIcon.png" }) {
                childImageSharp {
                    fixed(width: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            oilAndGasIcon: file(relativePath: { eq: "OilAndGasIcon.png" }) {
                childImageSharp {
                    fixed(width: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            miningIcon: file(relativePath: { eq: "MiningIcon.png" }) {
                childImageSharp {
                    fixed(width: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            railIcon: file(relativePath: { eq: "RailIcon.png" }) {
                childImageSharp {
                    fixed(width: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            tdiLogoBlue: file(relativePath: { eq: "TDILogoBlue.png" }) {
                childImageSharp {
                    fixed(width: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            railworksLogoBlack: file(relativePath: { eq: "RailWorksLogoBlack.png" }) {
                childImageSharp {
                    fixed(width: 150) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            parsonsElectricLogo: file(relativePath: { eq: "ParsonsElectricLogo.jpg" }) {
                childImageSharp {
                    fixed(width: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            reconLogo: file(relativePath: { eq: "ReconLogo.png" }) {
                childImageSharp {
                    fixed(width: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    return (
        <Layout pageID={"homePage"}>
            <SEO
                title="Construction's Time and Production Tracking Software"
                description={"ALMobile is the construction industry's #1 enterprise mobile, web-based, ERP-integrated software app that gives your business the ability to track time, attendance, and productivity to streamline your processes in the cloud and on the go."}
            />
            <div className={"contentRow videoRow"}>
                <video
                    muted
                    loop
                    autoPlay
                    playsInline
                >
                    <source
                        src={loopVideo} type={"video/mp4"}
                    />
                </video>
                <div className={"subContentRow videoOverlap"}>
                    <div className={"textContainer"}>
                        <div
                            className={"level1 text"}
                        >
                            Make Work Simple
                        </div>
                        <div
                            className={"level2 text"}
                        >
                            ALMobile helps <strong>construction companies</strong>
                        </div>
                        <div
                            className={"level2 text"}
                        >
                            bring digital processes full circle to make
                        </div>
                        <div
                            className={"level2 text"}
                        >
                            everything easier
                        </div>
                        <Link className={"level2 btn"} to={`/events/`} state={{component: "webinars"}}>
                            See Our Software in Action
                        </Link>
                    </div>
                </div>
            </div>
            <ContentRow
                className={["pageFold", "ALMobileOverviewRow"]}
                level1={["More than just an app", " Bring your company's data full circle"]}
                level2={[`ALMobile helps streamline and consolidate your field data
                tracking and payroll processes together to make everything
                easier. Start tracking employee time, attendance, and project
                information accurately and efficiently`]}
                imgData={imgData.fullClientDesktop.childImageSharp.fluid}
                imgAlt={"ALMobile Desktop"}
            />
            <div className={"contentRow productRow"}>
                <div className={"subContentRow"}>
                    <div className={"productRowHeader"}>Explore all the solutions in ALMobile</div>
                    <div className={"tileContainer"}>
                        <ALTile
                            icon={imgData.deviceIcon.childImageSharp.fixed}
                            iconAlt={"ALDevice Icon"}
                            mainText={"Time Tracking"}
                            subText={"Analyze, track, and record field labor time and project data with ease"}
                        />
                        <ALTile
                            icon={imgData.cogIcon.childImageSharp.fixed}
                            iconAlt={"ALCog Icon"}
                            mainText={"Production Tracking"}
                            subText={"Calculate, report, and predict project outcomes with the #1 production tracking software on the market"}
                        />
                        <ALTile
                            icon={imgData.kioskIcon.childImageSharp.fixed}
                            iconAlt={"ALKiosk Icon"}
                            mainText={"Attendance Tracking"}
                            subText={"Quick clock in / clock out tracking with instant verification on multiple job sites"}
                        />
                    </div>
                </div>
            </div>
            <div className={"contentRow howYouWinRow"}>
                <div className={"subContentRow"}>
                    <div className={"howYouWinHeader"}>
                        HOW YOU WIN
                    </div>
                    <NestedContentRow
                        iconData={{
                            iconType: "fixed",
                            icon: imgData.fieldWorkerIcon.childImageSharp.fixed,
                            iconAlt: "ALField Worker Icon"
                        }}
                        textData={{
                            level2: "Ease of Use in the Field",
                            level3: "Your field personnel have more important things to worry about than figuring out how to use complicated software. We believe technology should empower your workforce, not frustrate it."
                        }}
                    />
                    <NestedContentRow
                        iconData={{
                            iconType: "fixed",
                            icon: imgData.clipboardIcon.childImageSharp.fixed,
                            iconAlt: "ALClipboard Icon"
                        }}
                        textData={{
                            level2: "Unique Insights for the Office",
                            level3: "Cut through the noise of unorganized information and scattered data, and start analyzing what’s most important with ALMobile’s unique reporting capabilities integrated with your primary accounting system. Pull real-time project and employee data  that’s accurate, customizable, and easy to analyze."
                        }}
                    />
                    <NestedContentRow
                        iconData={{
                            iconType: "fixed",
                            icon: imgData.connectedIcon.childImageSharp.fixed,
                            iconAlt: "ALConnected Icon"
                        }}
                        textData={{
                            level2: "Always Connected",
                            level3: "With the ability to run without an internet connection, ALMobile helps companies work smarter and faster, together. Say goodbye to paper and relying on outdated software."
                        }}
                    />
                </div>
            </div>
            <div className={"contentRow ALMobileClients"}>
                <div className={"subContentRow"}>
                    <div className={"ALMobileClientsHeader"}>
                        Companies, both big and small, are growing their businesses with ALMobile
                    </div>
                    <div className={"iconRow"}>
                        <Img
                            style={{
                                display: "block"
                            }}
                            className={"icon railworks"}
                            fixed={imgData.railworksLogo.childImageSharp.fixed}
                            alt={"Railworks Logo"}
                        />
                        <Img
                            style={{
                                display: "block"
                            }}
                            className={"icon tdi"}
                            fixed={imgData.tdiLogo.childImageSharp.fixed}
                            alt={"TDI Logo"}
                        />
                        <Img
                            style={{
                                display: "block"
                            }}
                            className={"icon McCarthy"}
                            fixed={imgData.McCarthyLogo.childImageSharp.fixed}
                            alt={"McCarthy Logo"}
                        />
                        <Img
                            style={{
                                display: "block"
                            }}
                            className={"icon levelTen"}
                            fixed={imgData.level10Logo.childImageSharp.fixed}
                            alt={"Level 10 Logo"}
                        />
                        <Img
                            style={{
                                display: "block"
                            }}
                            className={"icon ce"}
                            fixed={imgData.cecmLogo.childImageSharp.fixed}
                            alt={"CECM Logo"}
                        />
                    </div>
                </div>
            </div>
            <div className={"contentRow ALMobileERPS"}>
                <div className={"subContentRow"}>
                    <div className={"ALMobileERPSHeader"}>
                        Integrate with the tools you already use
                    </div>
                    <div className={"iconRow"}>
                        <Img
                            style={{ display: "block" }}
                            className={"icon sage"}
                            fixed={imgData.sageLogo.childImageSharp.fixed}
                            alt={"Sage Logo"}
                        />
                        <Img
                            style={{ display: "block" }}
                            className={"icon viewpoint"}
                            fixed={imgData.viewpointLogo.childImageSharp.fixed}
                            alt={"Viewpoint One Logo"}
                        />
                        <Img
                            style={{ display: "block" }}
                            className={"icon oraclejde"}
                            fixed={imgData.oracleJDELogo.childImageSharp.fixed}
                            alt={"Oracle JDE Logo"}
                        />
                        <Img
                            style={{ display: "block" }}
                            className={"icon cmic"}
                            fixed={imgData.cmicLogo.childImageSharp.fixed}
                            alt={"CMIC Logo"}
                        />
                        <Img
                            style={{ display: "block" }}
                            className={"icon pentatech"}
                            fixed={imgData.pentaTechLogo.childImageSharp.fixed}
                            alt={"Penta Tech. Logo"}
                        />
                        <Img
                            style={{ display: "block" }}
                            className={"icon computerguidance"}
                            fixed={imgData.computerGuidanceLogo.childImageSharp.fixed}
                            alt={"Computer Guidance Logo"}
                        />
                    </div>
                    <Link className={"ALMobileERPSBtn"} to={`/events/`} state={{component: "webinars"}}>
                        Learn About Our Integrations
                    </Link>
                </div>
            </div>
            <div className={"contentRow ALMobileIndustries"}>
                <div className={"subContentRow"}>
                    <div className={"ALMobileIndustriesHeader"}>
                        Industries We Serve
                    </div>
                    <div className={"iconRow"}>
                        <ALTile
                            className={"icon"}
                            icon={imgData.constructionIcon.childImageSharp.fixed}
                            iconAlt={"Construction Icon"}
                            mainText={"CONSTRUCTION"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.electricalIcon.childImageSharp.fixed}
                            iconAlt={"Power Icon"}
                            mainText={"POWER"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.environmentalIcon.childImageSharp.fixed}
                            iconAlt={"Environmental Icon"}
                            mainText={"ENVIRONMENTAL"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.heavyHighwayIcon.childImageSharp.fixed}
                            iconAlt={"Heavy Highway Icon"}
                            mainText={"HEAVY HIGHWAY"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.industrialIcon.childImageSharp.fixed}
                            iconAlt={"Industrial Icon"}
                            mainText={"INDUSTRIAL"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.oilAndGasIcon.childImageSharp.fixed}
                            iconAlt={"Oil and Gas Icon"}
                            mainText={"OIL & GAS"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.miningIcon.childImageSharp.fixed}
                            iconAlt={"Mining Icon"}
                            mainText={"MINING"}
                            useWebinar={false}
                        />
                        <ALTile
                            icon={imgData.railIcon.childImageSharp.fixed}
                            iconAlt={"Rail Icon"}
                            mainText={"RAIL"}
                            useWebinar={false}
                        />
                    </div>
                </div>
            </div>
            <div className={"contentRow ALMobileClientQuotes"}>
                <div className={"subContentRow"}>
                    <div className={"ALMobileClientQuotesHeader"}>
                        We Are A Technology Leader and Partner For Our Clients
                    </div>
                    <div className={"ALMobileClientQuotesSubHeader"}>
                        SEE WHAT OUR PARTNERS ARE SAYING
                    </div>
                    <div className={"iconRow"}>
                        <ALTile
                            className={"q1"}
                            mainText={"ALMobile's time entry process is fast and accurate. I believe that is because ALMobile is very flexible, easy to use, and the most accurate method of recording data."}
                            subText={
                                <Img
                                    className={"quoteLogo"}
                                    style={{display: "block"}}
                                    fixed={imgData.parsonsElectricLogo.childImageSharp.fixed}
                                    alt={"Parsons Electric"}
                                />
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"q2"}
                            mainText={"After we implemented ALMobile, we cut down the hours it takes to process payroll by almost 70% within our first year. This company stands behind its product, and they are always there when you need them."}
                            subText={
                                <Img
                                    className={"quoteLogo"}
                                    style={{display: "block"}}
                                    fixed={imgData.tdiLogoBlue.childImageSharp.fixed}
                                    alt={"TDIndustries"}
                                />
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"q3"}
                            mainText={"It was our goal to rollout a time collection solution across two countries, United States and Canada, and have everyone at every location manage time collection the exact same way. ALMobile did more than deliver on this goal. The rollout of ALMobile was easier than I anticipated because it's not a complicated software."}
                            subText={
                                <Img
                                    className={"quoteLogo"}
                                    style={{display: "block"}}
                                    fixed={imgData.railworksLogoBlack.childImageSharp.fixed}
                                    alt={"Railworks"}
                                />
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"q4"}
                            mainText={"When you're dealing with massive amounts of different jobsite data every day, it is a huge help to have all of that important information on one easy screen, and ALMobile has done this. Tracking material usage, productivity, and budget statuses have never been easier for us, and I can confidently say that I'll never go back to manual entry methods again."}
                            subText={
                                <Img
                                    className={"quoteLogo"}
                                    style={{display: "block"}}
                                    fixed={imgData.reconLogo.childImageSharp.fixed}
                                    alt={"Recon"}
                                />
                            }
                            useWebinar={false}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default IndexPage
